import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Layout/SEO"
import Search from '../components/Search'
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"



const SearchPage = (props) => (
  <Layout seoProps={{title: "Search"}}>
    <Search {...props} />
  </Layout>
)

SearchPage.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}


export const searchResultItemsQuery = graphql`
query {
  allMongodbHerbsAilments {
  nodes {
    id
    name
  }
}
allMongodbHerbsHerbs(filter: {draft: {eq: false}, safeToPublish: {eq: true}}) {
  nodes {
    id
    name
    slug
    latinName
    description
    pictures {
      src
      alt_text
      taken_by
    }
  }
}
}
`

export default SearchPage
